import React from 'react';
import '../style/Categories.css';
import {Link} from "react-router-dom";

const Categories = () => {
    const categories = [
        // {name:"Dating",color:"#ffb3ba"},
        {name:"Communication",color:"#ffdfba"},
        {name:"Casual",color:"#ffffba"},
        {name:"Entertainment",color:"#baffc9"},
        {name:"Education",color:"#bae1ff"},
        {name:"Arcade",color:"#ffb3ba"},
        {name:"Finance",color:"#ffdfba"},
        {name:"Beauty",color:"#ffffba"},
        {name:"Adventure",color:"#baffc9"},
        // {name:"Casino",color:"#bae1ff"},
    ];
    return (
        <div className='Categories-Container'>
            <h1>Top Categories</h1>
            <div className='Categories'>
                {categories.map((category, index) => {
                    return (
                        <Link key={index} to={`/category/${category.name}`} style={{ background: category.color }} className={`${category.color} Category-Block`}>
                            <img src={`/assets/${category.name}.png`} alt={category.name}/>
                            <h3>{category.name}</h3>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Categories;
