import { atom } from "recoil"


export const test = atom({
    key: 'test',
    default: ['aaa', 'bbb', 'ccc']
});

export const test2 = atom({
    key: 'test2',
    default: ['aaaaaaa', 'bbbbbbbb', 'cccccc']
});

export const isLoading = atom({
    key: 'load',
    default: false
});

export const tag = atom({
    key: 'tag',
    default: ''
});

export const verticals = atom({
    key: 'verticals',
    default: ''
})

export const enums = atom({
    key: 'enums',
    default: ''
})
