import React, {useEffect, useRef, useState} from 'react';
import {searchApp} from "../api/Api";
import {useSetRecoilState} from "recoil";
import {isLoading} from "../api/Atoms";
import {useLocation, useNavigate} from 'react-router-dom';
import "../style/Home.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import LoadGif from "../style/load.gif";

const Search = () => {
    const location = useLocation();
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('')

    const imageRefs = useRef(new Map());
    const navigate = useNavigate();
    const setLoadingState = useSetRecoilState(isLoading)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setSearchQuery(queryParams.get('q'));
        const searchQuery = queryParams.get('q');
        setLoadingState(true)

        searchApp(searchQuery).then(data => {
            setSearchResults(data);
            setLoadingState(false)
        });
    }, [location]); // Depend on location to re-run effect when it changes

    const loadImage = (image) => {
        const src = image.dataset.src;
        // Only swap the `src` if the new `src` is valid
        if (src) {
            image.src = src;
            image.classList.add('loaded');
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, imgObserver) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) return;

                    const lazyImage = entry.target;
                    loadImage(lazyImage);
                    imgObserver.unobserve(lazyImage); // Stop observing the current target
                });
            },
            {
                rootMargin: '0px 0px 200px 0px', // Load images 200px before they come into view
            }
        );

        imageRefs.current.forEach((img) => observer.observe(img));

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [searchResults]); // Re-run effect if `topApps` changes

    const goTo = (path) => {
        navigate('/' + encodeURIComponent(path))
    }

    return (
        <div>
            <div className="Search-Container">
                <h1>Search Results For: {searchQuery}</h1>
                <span>{searchResults.length} result where found!</span>
                <div className="Search-Result-Container">
                    {searchResults.map((result, index) => (
                        <div key={index} className='App-Container' onClick={() => goTo(result.title)}>
                            <img
                                ref={(el) => imageRefs.current.set(index, el)}
                                src={result.icon}
                                alt={result.title}
                                onError={(e) => { e.target.onerror = null; e.target.src = LoadGif; }}
                                className="lazy-load" // Use this class to apply any styles before the image is loaded
                            />
                            <h3>{result.title}</h3>
                            {result.category ? <p>{result.category[0]}</p> :<span>&nbsp;</span> }
                            <p>
                                {result.rating}
                                <FontAwesomeIcon icon={faStar}/>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default Search;
