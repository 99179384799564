import React from 'react';
import { Link } from 'react-router-dom';
import '../style/Single.css';

const AdditionalDetails = ({ googleData }) => {
    if (!googleData || !googleData.item) {
        return null;
    }

    const details = [
        { label: 'Category', value: googleData.item.main_category, isLink: true, linkTo: `/category/${googleData.item.main_category}` },
        { label: 'Version', value: googleData.item.version },
        { label: 'Size', value: googleData.item.size },
        { label: 'Os Version', value: googleData.item.minimum_os_version },
        { label: 'Downloads', value: googleData.item.installs },
        { label: 'Package Name', value: googleData.app_id },
        { label: 'Price', value: googleData.item.price.current !== 0 ? `${googleData.item.price.current} ${googleData.item.price.currency}` : 'Free' },
        { label: 'Offered By', value: googleData.item.developer },
    ];

    return (
        <div className="Additional-Details-Container">
            <h2>Additional Details</h2>
            <div className="Details-Wrapper">
                {details.map((detail, index) =>
                    detail.value ? (
                        <div key={index} className="Ditail-Wrapper">
                            <span>{detail.label}</span>
                            {detail.isLink ? (
                                <Link className="Ditail-Wrapper-Link" to={detail.linkTo}>{detail.value}</Link>
                            ) : (
                                <span>{detail.value}</span>
                            )}
                        </div>
                    ) : null
                )}

            </div>
        </div>
    );
};

export default AdditionalDetails;
