import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Search from "./components/Search";
import SingleApp from "./components/SingleApp";
import SingleCategory from "./components/SingleCategory";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Loader from "./components/Loader";
import PageNotFound from "./components/PageNotFound";

function App() {
    return (
        <BrowserRouter>
            <Loader/>
            <Navbar/>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/search" element={<Search/>}/>
                    <Route path={'/category/:category'} element={<SingleCategory/>}/>
                    <Route path="/privacy-policy" element={<Privacy/>}/>
                    <Route path="/terms-of-use" element={<Terms/>}/>
                    <Route path={'/:title'} element={<SingleApp/>}/>
                    <Route path="/404" element={<PageNotFound />} />
                </Routes>
            </div>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
