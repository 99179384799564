import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {getAllApps, getCategory} from "../api/Api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {useSetRecoilState} from "recoil";
import {isLoading} from "../api/Atoms";
import LoadGif from "../style/load.gif";

const SingleCategory = () => {
    const [categoryData, setCategoryData] = useState('')
    const [skip, setSkip] = useState(0)
    const location = useLocation();
    let pathName = location.pathname;
    const pathSegments = pathName.split('/').filter(segment => segment !== '');
    const lastSegment = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : '';
    const setLoadingState = useSetRecoilState(isLoading)
    const navigate = useNavigate();



    useEffect(() => {
        setLoadingState(true);
        getCategory(decodeURIComponent(lastSegment),skip).then((data) => {
            if (Array.isArray(data) && data.length === 0) {
                navigate('/404');
            } else {
                setCategoryData(data);
                setSkip(skip + 30)
            }
            setLoadingState(false);
        });
    }, []);

    const goTo = (path) => {
        navigate('/' + encodeURIComponent(path))
    }

    const loadImage = (image) => {
        const src = image.dataset.src;
        // Only swap the `src` if the new `src` is valid
        if (src) {
            image.src = src;
            image.classList.add('loaded');
        }
    };
    const imageRefs = useRef(new Map());
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, imgObserver) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) return;

                    const lazyImage = entry.target;
                    loadImage(lazyImage);
                    imgObserver.unobserve(lazyImage); // Stop observing the current target
                });
            },
            {
                rootMargin: '0px 0px 200px 0px', // Load images 200px before they come into view
            }
        );

        imageRefs.current.forEach((img) => observer.observe(img));

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [categoryData]);

    const loadMore = () => {
        setLoadingState(true);
        getCategory(decodeURIComponent(lastSegment),skip).then((data) => {
            setCategoryData(prevState => [...prevState, ...data]);
            setSkip(skip + 30)
            setLoadingState(false);
        });
    }

    return (
        <div className='Category-Container'>
            {categoryData && categoryData.length > 0 ? <h2>Top {lastSegment} Apps</h2> : null}
            <div className='Category'>
                {categoryData && categoryData.length > 0 ? (
                    categoryData.map((result, index) => (
                        <div key={index} className='App-Container' onClick={() => goTo(result.title)}>
                            <img
                                ref={(el) => imageRefs.current.set(index, el)}
                                src={result.icon}
                                alt={result.title}
                                onError={(e) => { e.target.onerror = null; e.target.src = LoadGif; }}
                            />
                            <h3>{result.title}</h3>
                            {result.category ? <p>{result.category[0]}</p> : <span>&nbsp;</span>}
                            <p>
                                {result.rating}
                                <FontAwesomeIcon icon={faStar}/>
                            </p>
                        </div>
                    ))
                ) :
                   null
                }
            </div>
            <button className='Load-More' onClick={() => loadMore()}>Load More</button>
        </div>

    );
};

export default SingleCategory;
